<template>
    <div class="dataposting">
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool -->
        <b-row style="margin-left: 15px; margin-right: 0px;">
            <b-container class="main-content mt-3 p-0 mb-4">
                <!-- <portal-bar-tool
                    :showReportingMenu="showReportingMenu"
                    :hidetableSelect="hidetableSelect"
                ></portal-bar-tool> -->
                <!-- <div class="my-3">Text Instructions Here</div> -->
                <b-button
                    rounded
                    class="buttonSelect"
                    :disabled="validateInput"
                    @click="addPostedLayer()"
                >
                    Apply options
                </b-button>
                <div>
                    <h5 for="input-invalid">Posting layer name</h5>

                    <b-form-group class="md-4 mb-0" role="group">
                        <CustomFloatingLabel
                            class="postingName"
                            :config="{
                                label: 'Name',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                placeholder="Name"
                                type="text"
                                class="inputbox mb-4"
                                v-model="layerName"
                                autocomplete="off"
                            />
                        </CustomFloatingLabel>
                    </b-form-group>
                    <div>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <h5>Select field to display</h5>
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Field value',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fieldName"
                                    :options="fieldData"
                                    text-field="fieldData"
                                    value-field="fieldData"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Field value</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                    </div>
                    <div>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <h5>Group by options</h5>
                            <div class="aggr mb-3">
                                <div
                                    v-for="(item, index) in aggregateOption"
                                    :key="index"
                                >
                                    <img
                                        src="../../../assets/OverflowMenu.svg"
                                        font-scale="1.5"
                                        class="overflow-icon"
                                        v-if="overflowMenu"
                                    />
                                    <div class="aggregate-row">
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Group by field',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="
                                                    item.aggregateOptionField
                                                "
                                                @change="addAggrType(index)"
                                                id="inline-form-custom-select-pref"
                                                :options="
                                                    fieldAggregateDataUnique
                                                "
                                                text-field="fieldAggregateDataUnique"
                                                value-field="fieldAggregateDataUnique"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Group by
                                                        field</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <span
                                            v-if="index != 0"
                                            @click="deleteAggr(index)"
                                            ><img
                                                src="../../../assets/delete_icon.svg"
                                                class="ml-2"
                                        /></span>
                                    </div>
                                    <div
                                        v-for="(subItem,
                                        subIndex) in item.aggregateType"
                                        :key="subIndex"
                                    >
                                        <img
                                            src="../../../assets/L-shaped.svg"
                                        />
                                        <!-- v-if="aggrfieldType" -->
                                        <CustomFloatingLabel
                                            class="FloatingTool floatingIndent"
                                            :config="{
                                                label: 'Grouping method',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                class="aggrType m-r-36"
                                                id="inline-form-custom-select-pref"
                                                v-model="
                                                    subItem.aggregateTypeName
                                                "
                                                :options="
                                                    aggregateTypeData[index]
                                                "
                                                text-field="AggregateOptions"
                                                value-field="AggregateOptions"
                                            >
                                                <!-- <option v-for="j in aggregateType" :key="j">{{ j }}</option> -->
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Group
                                                        method</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <div
                                            class="dateAggregate"
                                            v-if="
                                                subItem.aggregateTypeName ==
                                                    'Closest to a provided date'
                                            "
                                        >
                                            <img
                                                src="../../../assets/L-shaped.svg"
                                                class="imgIndent"
                                            />
                                            <CustomFloatingLabel
                                                class="FloatingTool floatingdate"
                                                :config="{
                                                    label: 'Provided date',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <input
                                                    class="avlFilter mt-2 mb-4"
                                                    type="date"
                                                    v-if="
                                                        subItem.aggregateTypeName ==
                                                            'Closest to a provided date'
                                                    "
                                                    v-model="
                                                        subItem.aggregateDate
                                                    "
                                                />
                                            </CustomFloatingLabel>
                                            <a
                                                @click="
                                                    deleteAggr(index, subIndex)
                                                "
                                                class="deletebtn"
                                            ></a>
                                        </div>
                                    </div>
                                    <!--  -->
                                </div>
                            </div>
                        </b-form-group>
                    </div>
                    <div>
                        <p
                            class="normal_input headergroup mt-1 add-btn"
                            @click="addAggr()"
                        >
                            Add another grouping option +
                        </p>
                    </div>
                    <div>
                        <b-card no-body class="mb-1 accordion-more">
                            <b-card-header
                                header-tag="header"
                                class="p-0 mb-2"
                                role="tab"
                            >
                                <b-button
                                    class="view-more"
                                    block
                                    v-b-toggle:accordion-extraOptions
                                    ><span class="when-closed">Expand</span
                                    ><span class="when-open">Collapse</span>
                                    advanced options</b-button
                                >
                            </b-card-header>
                            <b-collapse
                                id="accordion-extraOptions"
                                accordion="my-accordion"
                                role="tabpanel"
                            >
                                <b-card-body>
                                    <h5>
                                        Position
                                        <span class="grey-text-sm"
                                            >(optional)
                                        </span>
                                    </h5>
                                    <b-container
                                        class="bv-example-row mt-3 mb-5"
                                    >
                                        <b-row
                                            cols="3"
                                            class="edit-position-wide w-260"
                                            v-model="editPosition"
                                        >
                                            <b-col
                                                class="border-bottom border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'tl_button edit':
                                                            dataLabelPosition !=
                                                            'topLeft',
                                                    }"
                                                    @click="
                                                        dataPosition('topLeft')
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Top</span
                                                    ><br />
                                                    Left
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-bottom border-dark border-left p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'tc_button edit':
                                                            dataLabelPosition !=
                                                            'topCenter',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'topCenter'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Top</span
                                                    ><br />
                                                    Center
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-left border-bottom border-dark right-1 p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'tr_button edit':
                                                            dataLabelPosition !=
                                                            'topRight',
                                                    }"
                                                    @click="
                                                        dataPosition('topRight')
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Top</span
                                                    ><br />
                                                    Right
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-bottom border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'ml_button edit':
                                                            dataLabelPosition !=
                                                            'middleLeft',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'middleLeft'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Middle</span
                                                    ><br />Left
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-left border-bottom border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'mc_button edit':
                                                            dataLabelPosition !=
                                                            'middleCenter',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'middleCenter'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Middle</span
                                                    ><br />
                                                    Center
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-bottom border-left border-dark  p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'mr_button edit':
                                                            dataLabelPosition !=
                                                            'middleRight',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'middleRight'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Middle</span
                                                    ><br />Right
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-dark  p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'bl_button edit':
                                                            dataLabelPosition !=
                                                            'bottomLeft',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'bottomLeft'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Bottom</span
                                                    ><br />
                                                    Left
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-left border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'bc_button edit':
                                                            dataLabelPosition !=
                                                            'bottomCenter',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'bottomCenter'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Bottom</span
                                                    ><br />Center
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-dark border-left p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'br_button edit':
                                                            dataLabelPosition !=
                                                            'bottomRight',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'bottomRight'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Bottom</span
                                                    ><br />
                                                    Right
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    <div class="font-option-m">
                                        <b-form-group
                                            id="input-group-3"
                                            label-for="input-3"
                                        >
                                            <h5>
                                                Font options
                                                <span class="grey-text-sm"
                                                    >(optional)</span
                                                >
                                            </h5>
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Size',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="fontSize"
                                                    :options="fontSizeData"
                                                    text-field="Size"
                                                    value-field="Size"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Size</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Style',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="fontStyle"
                                                    :options="fontStyleData"
                                                    text-field="Style"
                                                    value-field="Style"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Style</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                            <div class="pickColor wrap">
                                                <div class="">
                                                    <CustomFloatingLabel
                                                        class="FloatingTool DataPosting"
                                                        :config="{
                                                            label: 'Color',
                                                            name: 'wrapper',
                                                            line: false,
                                                            scale: false,
                                                        }"
                                                    >
                                                        <color-picker
                                                            v-model="
                                                                featureColor
                                                            "
                                                        />
                                                    </CustomFloatingLabel>
                                                </div>
                                            </div>
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Type',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="fontType"
                                                    :options="fontTypeData"
                                                    text-field="Type"
                                                    value-field="Type"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Type</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                        </b-form-group>
                                        <h5 class="trans-sm">Transparency</h5>
                                        <div class="d-flex my-4">
                                            <span
                                                class="font-weight-bold indigo-text mr-2 mt-1"
                                                >0</span
                                            >
                                            <form
                                                class="range-field grey-color"
                                                width="125px"
                                            >
                                                <!--This is the form that handles the slider for the mini stuff/mini map edit-->
                                                <input
                                                    class="border-0 inputrange"
                                                    type="range"
                                                    v-model="transparencyValue"
                                                    min="0"
                                                    step="1"
                                                    max="100"
                                                />
                                            </form>
                                            <span
                                                class="font-weight-bold indigo-text ml-2 mt-1"
                                                margin-left="-25px"
                                                padding-left="0px"
                                                >100</span
                                            >
                                            <p margin-top="50px">
                                                <input
                                                    type="text"
                                                    margin-top="50px"
                                                    class="input-value"
                                                    display="inline-block"
                                                    min="0"
                                                    max="100"
                                                    maxlength="3"
                                                    size="3ch"
                                                    padding-top="5px"
                                                    v-model.lazy="
                                                        transparencyValue
                                                    "
                                                    v-on:keyup.enter="
                                                        validateTransparency()
                                                    "
                                                    v-click-outside="
                                                        validateTransparency()
                                                    "
                                                />%
                                            </p>
                                        </div>
                                    </div>
                                    <b-form-group
                                        id="input-group-3"
                                        label-for="input-3"
                                        class="bottom-p-75 m-r-36"
                                    >
                                        <h5>
                                            Symbology
                                            <span class="grey-text-sm"
                                                >(optional)</span
                                            >
                                        </h5>
                                        <b-dropdown
                                            class="symbology text-left mb-4"
                                            text="Symbology"
                                        >
                                            <template slot="button-content">
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/circle.svg"
                                                    v-if="symbology == 'circle'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/arrow.svg"
                                                    v-if="symbology == 'arrow'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/flag.svg"
                                                    v-if="symbology == 'flag'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/star_5.svg"
                                                    v-if="symbology == 'star'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/cross_4.svg"
                                                    v-if="symbology == 'cross'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/cross_3.svg"
                                                    v-if="symbology == 'cross3'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/cross_5.svg"
                                                    v-if="symbology == 'cross5'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/star_3.svg"
                                                    v-if="symbology == 'star3'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/star_4.svg"
                                                    v-if="symbology == 'star4'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/polygon_5.svg"
                                                    v-if="
                                                        symbology == 'polygon5'
                                                    "
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/polygon_4.svg"
                                                    v-if="
                                                        symbology == 'polygon4'
                                                    "
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/polygon_3.svg"
                                                    v-if="
                                                        symbology == 'polygon3'
                                                    "
                                                />
                                            </template>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'circle'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/circle.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'arrow'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/arrow.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'flag'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/flag.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'star'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/star_5.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'cross'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/cross_4.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'cross3'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/cross_3.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'cross5'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/cross_5.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'star3'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/star_3.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'star4'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/star_4.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'polygon3'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/polygon_3.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'polygon4'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/polygon_4.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'polygon5'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../../assets/polygon_5.svg"
                                                />
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </b-form-group>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </b-container>
        </b-row>
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool ENDS BELOW -->
        <img
            id="referenceCircleImage"
            src="../../../assets/circle.svg"
            class="d-none"
        />
        <img
            id="referenceArrowImage"
            src="../../../assets/arrow.svg"
            class="d-none"
        />
        <img
            id="referenceFlagImage"
            src="../../../assets/flag.svg"
            class="d-none"
        />
        <img
            id="referenceStarImage"
            src="../../../assets/star_5.svg"
            class="d-none"
        />
        <img
            id="referencePlusImage"
            src="../../../assets/cross_4.svg"
            class="d-none"
        />
        <img
            id="referenceCross3Image"
            src="../../../assets/cross_3.svg"
            class="d-none"
        />
        <img
            id="referenceCross5Image"
            src="../../../assets/cross_5.svg"
            class="d-none"
        />
        <img
            id="referenceStar3Image"
            src="../../../assets/star_3.svg"
            class="d-none"
        />
        <img
            id="referenceStar4Image"
            src="../../../assets/star_4.svg"
            class="d-none"
        />
        <img
            id="referencePolygon3Image"
            src="../../../assets/polygon_3.svg"
            class="d-none"
        />
        <img
            id="referencePolygon4Image"
            src="../../../assets/polygon_4.svg"
            class="d-none"
        />
        <img
            id="referencePolygon5Image"
            src="../../../assets/polygon_5.svg"
            class="d-none"
        />
    </div>
    <!-- Main Div Of this Component end below -->
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { userService } from '../../../services/users.service';
import { projectService } from '../../../services/project.service';

import { datapostingMixin } from './dataposting.mixin.js';

import CustomFloatingLabel from '../../CustomFloatingLabel.vue';
import ColorPicker from '../ColorPicker.vue';

export default {
    name: 'DataPostSidebar',
    props: {
        datasetId: Number,
    },
    components: {
        CustomFloatingLabel,
        ColorPicker,
    },
    mixins: [datapostingMixin],
    data() {
        return {
            //project layer data, remove after api call is completed
            clustersHaveBeenRefreshed: false,
            postedDataLayers: null,
            mapPostingLayerData: null,
            //end
            sitecode: null,
            markerpopup: false,
            mapBounds: null,
            postingSource: null,
            colorSelected: null,
            rotation: '0°',
            transparencyValue: 0,
            editTransparencyValue: null,
            editFieldName: '',
            editPosition: null,
            createLayerPage: true,
            layerDetails: {},
            aggregateTypeData: [],
            aggregateOption: [
                {
                    aggregateType: [
                        {
                            aggregateDate: '',
                            aggregateTypeName: '',
                        },
                    ],
                    aggregateOptionField: '',
                },
            ],
            modalLayerName: '',
            modalLayerId: '',
            layerId: '',
            value: 0,
            options: [],
            layerList: [],
            pickColor: false,
            postLayerActive: true,
            layerName: '',
            editedPostingId: null,
            symbology: null,
            overflowMenu: false,
            selected: null,
            hideheader: 0,
            aggrfieldCounter: 0,
            aggrfield: [],
            dataLabelPosition: null,
            edit_clicked: false,
            postingId: 0,
            selectedPostingId: '',
            fieldData: [],
            layerEditIndex: '',
            editLayer: false,
            showLayer: true,
            aggrfieldType: false,
            thisfilename: null,
            reportingTool: false,
            AggregateOptions: null,
            showPage: false,
            editAggrTypeRevised: [],
            clickedGeometry: { properties: [], hasFile: false, files: [] },
            clusterOptions: {
                disableClusteringAtZoom: 20,
                showCoverageOnHover: false,
                chunkedLoading: true,
            },
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
            showFailure: false,
            showSuccess: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            featureColor: '#000000',
            postedDataLayerParams: null,
            projectDataLayers: [],
        };
    },
    created() {
        this.getFontSize();
        this.getFontType();
        this.getFontStyle();
        this.selectField('Display field');
        this.selectAggregateField('Data Posting Tool');
        this.allAggregateOption();
    },
    mounted: function() {
        this.getProjectLayerData();
        this.layerName = 'layer' + (+this.postedMapLayers.length + 1);
        this.$root.$on('clickedFilterData', function(value) {
            this.currentSwitchState = value;
        });
        this.localLayer = this.postedMapLayers;
        this.layerList = this.postedMapLayers;
        this.postingId = Date.now();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapActions('projects', {
            updateSelectedLayerAccordion: 'updateSelectedLayerAccordion',
        }),
        getProjectLayerData() {
            let projectIDs = [];
            this.$store.state.projects.selectedProjects.forEach((proj) => {
                projectIDs.push(proj.ProjectID);
            });
            if (projectIDs.length > 0) {
                projectService
                    .getProjectLayers(projectIDs.toString())
                    .then((res) => {
                        this.projectDataLayers = res.data;

                        let payload2 = [];
                        let allWmsLayers = [];
                        this.projectDataLayers.forEach((proj) => {
                            if (
                                proj.ProjectLayers == undefined ||
                                proj.ProjectLayers.length == 0
                            ) {
                                const emptyLayers = [
                                    {
                                        DisplayName:
                                            'No layers defined for this project',
                                        Geometry: {
                                            features: [],
                                            type: 'FeatureCollection',
                                            visible: true,
                                        },
                                    },
                                ];
                                proj.ProjectLayers = emptyLayers;
                            } else if (proj.WMSLayer != null) {
                                payload2 = [];
                                allWmsLayers = [];
                                this.projectDataLayers.forEach((item) => {
                                    allWmsLayers = [...item.WMSLayer];
                                });
                                allWmsLayers.forEach((item) => {
                                    if (item.WMSUrl) {
                                        payload2.push({
                                            url:
                                                item.WMSUrl +
                                                `?authkey=${item.authkey}`,
                                            name: item.WMSLabel,
                                            visible: item.IsVisible,
                                            format: item.WMSFormat,
                                            layers: item.WMSName,
                                            transparent: true,
                                            attribution: '',
                                            options: {},
                                        });
                                    }
                                });
                                this.wmsLayers = payload2;
                            }
                        });

                        //projectDataLayers styling data management
                        this.projectDataLayers.forEach((proj) => {
                            proj.ProjectLayers.forEach((lyr) => {
                                let projStyling = {
                                    FontColor: lyr.FontColor,
                                    FontRotation: lyr.FontRotation,
                                    FontSize: lyr.FontSize,
                                    FontStyle: lyr.FontStyle,
                                    FontTransparency: lyr.FontTransparency,
                                    FontType: lyr.FontType,
                                    LineColor: lyr.LineColor,
                                    LineStyle: lyr.LineStyle,
                                    LineWidth: lyr.LineWidth,
                                    PolygonColor: lyr.PolygonColor,
                                    PolygonStyle: lyr.PolygonStyle,
                                    PolygonTransparency:
                                        lyr.PolygonTransparency,
                                    PolygonBorderColor: lyr.PolygonBorderColor,
                                    PolygonWidth: lyr.PolygonWidth,
                                    Symbology: lyr.Symbology,
                                    SymbologyColor: lyr.SymbologyColor,
                                };
                                lyr.Geometry.features.forEach((geom) => {
                                    geom.styling = projStyling;
                                });
                            });
                        });
                    });
            }
        },
        addPostedLayer() {
            this.updateSelectedLayerAccordion('posted');
            this.clustersHaveBeenRefreshed = false;
            if (this.currentSwitchState) {
                this.$emit(
                    'clickedState',
                    this.currentSwitchState,
                    this.aggregateOption
                );
                this.$root.$refs.B.dataToolTab(true);
            } else {
                this.displayMap();
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [month, day, year].join('-');
        },
        displayMap() {
            this.showLayer = true;
            let aggregateDataFinal = this.buildAggregateString();
            this.postDataPoints(aggregateDataFinal, this.layerName);
        },
        dataPosition(item) {
            this.dataLabelPosition = item;
        },
    },
    computed: {
        ...mapState({
            projectMapLayers: (state) => state.projectLayers.projects,
            postedMapLayers: (state) =>
                state.projects.mapData.geojsonLayers.posted,
            secondFilterOptions: (state) => state.gridFilters.secondLevelFilter,
            gridFilteroptions: (state) => state.gridFilters.gridFilter,
        }),
        validateInput() {
            let disableApply = false;
            if (this.layerName == '' || this.layerName == null) {
                disableApply = true;
            }
            if (this.fieldName == null || this.fieldName == '') {
                disableApply = true;
            }
            for (let i in this.aggregateOption) {
                if (
                    this.aggregateOption[i].aggregateOptionField == '' ||
                    this.aggregateOption[i].aggregateOptionField == null
                ) {
                    disableApply = true;
                } else {
                    if (this.aggregateOption[i].aggregateType.length > 0) {
                        for (let m in this.aggregateOption[i].aggregateType) {
                            if (
                                this.aggregateOption[i].aggregateType[m]
                                    .aggregateTypeName == '' ||
                                this.aggregateOption[i].aggregateType[m]
                                    .aggregateTypeName == null
                            ) {
                                disableApply = true;
                            } else if (
                                this.aggregateOption[i].aggregateType[m]
                                    .aggregateTypeName ==
                                'Closest to a provided date'
                            ) {
                                if (
                                    this.aggregateOption[i].aggregateType[m]
                                        .aggregateDate == ''
                                ) {
                                    disableApply = true;
                                }
                            }
                        }
                    }
                }
            }

            return disableApply;
        },

        displayedTransparencyValue() {
            //maybe can use this to better separate the slider and input box?
            return this.transparencyValue + '%';
        },
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';

.main-content {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;

    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }

    h3 {
        font-size: 22px;
    }
}

.main-content.edit-layer {
    max-height: 485px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
}

.inputbox :focus {
    border: none;
}

.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}

.pencil-icon {
    cursor: pointer;
}

.buttonSelect {
    position: absolute;
    right: 50px;
}

.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}

.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}

.aggrType {
    margin-left: 36px;
    margin-top: -23px;
    width: 400px;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}

.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}

.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}

.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}

.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}

.edit-position-wide .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 86.67px;
    height: 70px;
    border-radius: 0;
}
.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}

.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}

.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}

.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}

.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}

.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}

.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}

.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.input-value {
    display: inline-flex;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 4px;
    margin-right: 0px;
    size: 3ch;
    text-align: center;
    border-style: hidden;
}

@media only screen and (max-width: 767px) {
    .input-value {
        margin-top: 7px;
    }
}

.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}

.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}

.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}

.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}

.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}

.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

/* ------------------------------------------Position Button Css Ends Here------------------------------- */
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}

.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}

/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}

/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}

.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}

.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}

.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}

.btn-transparent {
    position: inherit;
    top: 20px;
    right: 10px;
    height: 40px;
    width: 40px;
}

// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    top: 18px;
    left: 45px;
    border: 0 !important;
}

.hidebtn {
    left: 50px;
}

.dataposting .portalbarMain {
    border-block: 0 !important;
}

.layer {
    width: 240px;

    .inputrange.edit {
        width: 125px !important;
    }
}

.range-field {
    width: 365px;
}

@media only screen and (max-width: 767px) {
    .inputrange {
        width: 215px;
    }

    .range-field {
        width: 215px;
    }
}
.inputrange {
    width: 125px !important;
}

.headergroup {
    font-size: 14px;
}
.input-value {
    font-size: 12px;
    size: 3ch;
}

.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}

.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}

.collapsed .when-opened {
    display: none;
}

.not-collapsed .when-closed {
    display: none;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}

.dateAggregate {
    margin-top: -22px;
}
.dataposting .pickColor .e-colorpicker-wrapper .e-btn.e-btn.e-icon-btn {
    width: 45px !important;
}

.accordion-more {
    width: 500px;
}

.view-more {
    height: 50px;
}

.view-more.not-collapsed:hover {
    background-color: #006395;
}

.view-more.collapsed {
    background-color: #006395;
}
.view-more.collapsed:hover {
    background-color: #00496d !important;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
</style>
